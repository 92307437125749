import variables from './cssInJs/variables';
import { getIdToken } from 'lib/auth/services/authHelper';

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints,
};

const firebaseSettings = {
  apiKey: "AIzaSyCZ1qc5a2jj4T17eHmkpsRsODabmW2LBm8",
  authDomain: "chiroevents-a8081.firebaseapp.com",
  projectId: "chiroevents-a8081",
  storageBucket: "chiroevents-a8081.appspot.com",
  messagingSenderId: "658456718980",
  appId: "1:658456718980:web:2ffc6fe79a440b898c5cd1"
};

const apiNetJwt = async (options) => {
  const token = await getIdToken();
  //console.log(token)
  if (token) {
    options.headers = options.headers || {};
    options.headers['Authorization'] = `Bearer ${token}`;
  }
};
export default {
  cssEmotion,
  firebaseSettings,
  apiNetJwt,
};
