import React from 'react';
import env from 'env';
import { useGet, useRouter } from 'components';
import { Loading, ErrorPopup } from 'components';
import { useAuth } from 'auth';
import { MyEvents } from './panels/EventList';
import { MyBookmarks } from './panels/Bookmark';
import { EventManagement } from './panels/EventManagement';
import { getMemberType } from 'enum';

export function DashboardLoading() {
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const auth = useAuth();
  const get = useGet();
  const { query } = useRouter();
  const setCorporate = query.setCorporate || '';

  if (setCorporate) {
    localStorage.setItem('corporateId', setCorporate);
  }

  React.useEffect(() => {
    get.send(env.apiBase + `/api/member/getDashboard?corporateId=${localStorage.getItem('corporateId')}`);
    setSendingStatus('pending');
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (sendingStatus === 'done') auth.setMemberInfo(data.memberInfo);
    // eslint-disable-next-line
  }, [sendingStatus]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    let errors = get.errors;
    let title = 'Loading Error';
    if (get.containsErrorMessage('inactive account')) {
      title = 'Account Disabled';
      errors = 'This account is inactive or temporarily disabled.';
    }
    return <ErrorPopup title={title} errors={errors} />;
  }

  const data = get.response;

  if (get.done() && sendingStatus === 'pending') {
    setSendingStatus('done');
  }

  const memberType = data?.memberInfo?.memberType;

  return (
    <>
      <h2>
        <strong>{getMemberType(memberType)} Dashboard</strong>
      </h2>
      {memberType === 0 /*Attendee*/ && (
        <div className='row'>
          <div className='col-md-8'>
            <MyEvents dashboard={data} />
            <MyBookmarks dashboard={data} />
          </div>
          <div className='col-md-4'></div>
        </div>
      )}
      {(memberType === 1 /*Organiser*/ || memberType === 99) /*Admin*/ && (
        <div className='row'>
          <div className='col-md-8'>
            <MyEvents dashboard={data} />
            <MyBookmarks dashboard={data} />
          </div>
          <div className='col-md-4'>
            <EventManagement dashboard={data} />
          </div>
        </div>
      )}
    </>
  );
}
