const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  blueGreyColor : '#2e6072',
  dkGray : '#3b3b3b',
  tealColor : '#00a69b'
};

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: colors.blueGreyColor,
  fontColor: colors.dkGray,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;