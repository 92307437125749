import React from 'react';
import { useAuth } from 'auth';
import { useRouter, SiteLink } from 'components';

export function Login(props) {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [pending, setPending] = React.useState(false);
  const auth = useAuth();

  const { query } = useRouter();
  const returnUrl = query.returnUrl || '/member/dashboard';

  const router = useRouter();

  function submit(e) {
    setPending(true);
    //console.log('loggin', username, password)
    e.preventDefault();
    e.stopPropagation();

    auth
      .signin(username, password)
      .then((user) => {
        console.log(user);
        setPending(false);
        setError('');
        router.push(returnUrl);
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
        setError(error.message);
      });
  }
  return (
    <div className='sign-in auth-panel' style={{ maxWidth: '600px', width: '90%', margin: 'auto' }}>
      <h1>Log In</h1>
      <div className='loading-panel-holder'>
        {/* <app-loading [loadingStatus]="loadingStatus"></app-loading> */}
        <div className='panel-content'>
          <form method='POST' noValidate='' onSubmit={submit}>
            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input
                id='email'
                type='email'
                className='form-control'
                name='email'
                required
                autoFocus
                autoComplete='false'
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
              <div className='invalid-feedback'>Email is invalid</div>
            </div>

            <div className='form-group'>
              <label htmlFor='password'>Password</label>
              <input
                id='password'
                type='password'
                className='form-control'
                name='password'
                required
                autoComplete='false'
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              <div className='invalid-feedback'>Password is required</div>
            </div>
            {/* 
        <!-- <div className="form-group">
                  <div className="custom-checkbox custom-control">
            <input type="checkbox" name="remember" id="remember" className="custom-control-input" #remember>
                    <label for="remember" className="custom-control-label">Remeber Me</label>
                  </div>
        </div> --> */}

            <div className='form-group m-0'>
              <button type='submit' disabled={pending} className='btn btn-primary btn-block'>
                {pending && <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>}
                <span className=''>Log In</span>
              </button>
            </div>

            {error && <div style={{ color: 'red' }}>{error}</div>}

            {/* <div className="form-group m-0">
          <button [disabled]="submitting" type="submit" className="btn btn-primary btn-block">
            <span *ngIf="submitting" className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            <span className="">Log In</span>
          </button>
        </div> */}
          </form>

          {/* <div className="social-logins">
        <span className="or">Or Log in with </span>
        <div className="form-group">
          <button type="button" className="btn btn-dark" (click)="googleAuth()">
            Google
          </button>
        </div>
      </div>
       */}
          <div style={{ marginTop: 20 }}>
            Don't have an account? <SiteLink to={'/member/signup'}>Sign Up</SiteLink>
          </div>
          <div style={{ marginTop: 20 }}>
            <SiteLink to={'/member/forgotPassword'}>Forgot Password?</SiteLink>
          </div>
        </div>
      </div>
    </div>
  );
}
