import React from 'react';
import utils from 'utils';
import { RegisterPopup } from './register/registerPopup';
import { MenuLoader } from 'layout/menuLoader';
import './eventView.scss';
import env from 'env';
import { SiteLink } from 'components';
import { IoReturnUpBack } from 'react-icons/io5';
import { BsBookmarkPlus, BsFillBookmarkCheckFill } from 'react-icons/bs';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { fetchPost } from 'lib/net-api/fetch';
import config from 'config';
import { useAuth } from 'auth';
import { postJwt } from 'site/_helpers/fetch';
const MySwal = withReactContent(Swal);

export function EventDetails({ event, isPreview = false }) {
  const auth = useAuth();
  const [bookmarked, setBookmarked] = React.useState(!!event?.bookmarked);
  const isPast = utils.date.toDateTime(event.startDateTime) - new Date() < 0;
  const fullyBooked = event.numOfPlaces && (event.usedNumOfPlaces || 0) >= event.numOfPlaces;

  async function bookmark() {
    await fetchPost(
      env.apiBase + '/api/event/Bookmark',
      {
        eventId: event.eventId,
      },
      null,
      config.apiNetJwt
    );
    setBookmarked(true);
  }

  async function unbookmark() {
    await postJwt('/api/event/Unbookmark', {
      eventId: event.eventId,
    });
    setBookmarked(false);
  }

  return (
    <div className=''>
      <MenuLoader />
      <div className='eventView'>
        <div className='eventView__left'>
          {isPast || fullyBooked ? (
            <div className=''>
              <button type='button' disabled className='btn btn-warning'>
                {fullyBooked ? 'Fully Booked' : 'Not Available'}
              </button>
            </div>
          ) : (
            <Book event={event} isPreview={isPreview} />
          )}
          <EventSummary event={event} />

          <div className=''>
            <button type='button' className='btn btn-outline-primary btn-block'>
              ADD TO CALENDAR
            </button>
          </div>

          <Map event={event} />
        </div>
        <div className='eventView__right'>
          <h2 className='eventTitle'>
            <strong>{event.eventTitle}</strong>
          </h2>
          <div className='returnUrl'>
            <SiteLink to='/' className='btn btn-sm btn-outline-primary'>
              <IoReturnUpBack /> Return to Events
            </SiteLink>
            {auth.loggedInSync && bookmarked && (
              <button className='btn btn-sm btn-warning' style={{ marginLeft: '1rem' }} onClick={unbookmark}>
                <BsFillBookmarkCheckFill /> Bookmarked!
              </button>
            )}
            {auth.loggedInSync && !bookmarked && (
              <button className='btn btn-sm btn-outline-primary' style={{ marginLeft: '1rem' }} onClick={bookmark}>
                <BsBookmarkPlus /> Bookmark
              </button>
            )}
          </div>
          <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
        </div>
      </div>
    </div>
  );
}

function EventSummary({ event }) {
  const lines = [];
  const line = (iconName, text) => ({ iconName, text });
  if (event.dateText) lines.push(line('calendar', event.dateText));
  if (event.timeText) lines.push(line('time', event.timeText));
  if (event.locationText) lines.push(line('location', event.locationText));
  if (event.priceText) lines.push(line('cost', event.priceText));
  if (event.eventTypeText) lines.push(line('session', event.eventTypeText));
  if (event.cpdText) lines.push(line('cpd-points', event.cpdText));

  return (
    <div className='summary'>
      {lines.map((x, i) => (
        <SummaryLine key={i} line={x}></SummaryLine>
      ))}
    </div>
  );
}

function SummaryLine({ line: { iconName, text } }) {
  const icon = iconName ? <img src={`/assets/icons/${iconName}.svg`} alt={iconName} /> : null;
  return (
    <div className='summaryLine'>
      {icon}
      <span className='summaryLine__text'>{text}</span>
    </div>
  );
}

function Book({ event, isPreview }) {
  const [showPopup, setShowPopup] = React.useState(false);
  const [registered, setRegistered] = React.useState(!!event.register?.bookingId);

  let priceEl = null;
  if (event.eventType === 0 /*PurchaseTicket*/) {
    priceEl = (
      <div className='pr-8'>
        <div className='font-bold text-3xl'>
          {(event.price || 0) === 0 ? 'FREE' : utils.money.formatMoney(event.price)}
        </div>
      </div>
    );
  }

  return (
    <div>
      {registered && (
        <div className='flex items-center max-w-md'>
          <button type='button' className='btn btn-warning btn-block '>
            YOU'RE REGISTERED
          </button>
        </div>
      )}
      {!registered && (
        <div className='flex items-center max-w-md'>
          {priceEl}
          <div className=''>
            <button
              type='button'
              className='btn btn-primary btn-block btnRegister'
              onClick={() => (isPreview ? alert('Not supported in Preview mode') : setShowPopup(true))}
            >
              REGISTER NOW
            </button>
          </div>
        </div>
      )}
      <RegisterPopup
        showPopup={showPopup}
        onClosed={(e, isSuccessful) => {
          if (e) e.stopPropagation();
          setShowPopup(false);
          if (isSuccessful) {
            onRegistrationSubmitted();
            setRegistered(true);
          }
        }}
        event={event}
      />
    </div>
  );
}

function onRegistrationSubmitted() {
  MySwal.fire({
    title: 'Successfully submitted.',
    text: 'A confirmation email has been sent. Please check your inbox.',
    icon: 'success',
    customClass: {
      confirmButton: 'btn btn-primary',
    },
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 2000,
  });
}

function Map({ event }) {
  if (!event.mapAddress) return null;
  const address = encodeURIComponent(event.mapAddress.replace(/&/gi, '/'));
  const addressUrl = `https://www.google.com/maps/embed/v1/place?key=${env.gmapApiKey}&q=${address}`;

  return (
    <div style={{ marginTop: '2rem' }}>
      <iframe
        width='100%'
        height='300'
        frameBorder='0'
        style={{ border: 0 }}
        title={address}
        src={addressUrl}
        allowFullScreen
      ></iframe>
    </div>
  );
}
