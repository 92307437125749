import React from 'react';
import env from 'env';
import { useGet } from 'components';
// import { SiteLink } from 'components';
import { Loading, ErrorPopup } from 'components';
import { useRouter } from 'components';
import { SingleBannerPanel } from 'site/singleBanner/singlePanel';
import { EventDetails } from './eventDetails';
import { getBanner } from '../eventListing/eventHelper';

export function EventPreview() {
  const { query } = useRouter();
  const get = useGet();

  const slug = query.slug || '';

  React.useEffect(() => {
    console.log('getting...');
    get.send(env.apiBase + `/api/event/getPreviewEvent/${slug}`);
    // eslint-disable-next-line
  }, [slug]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  const event = get.response;
  const bannerImage = getBanner(event);

  return (
    <>
      <SingleBannerPanel heightSet={'standard'} bgUrl={bannerImage} />

      <div className='container' style={{ paddingBottom: '6rem' }}>
        <EventDetails event={event} isPreview={true} />
      </div>
    </>
  );
}
