import config from 'config';
import env from 'env';
import { fetchGet, fetchPost } from 'lib/net-api/fetch';

export async function postJwt(url, json) {
  await fetchPost(env.apiBase + url, json, null, config.apiNetJwt);
}
export async function getJwt(url) {
  await fetchGet(env.apiBase + url, null, config.apiNetJwt);
}
