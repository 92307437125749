import React from 'react';
import '../eventListing.scss';
import { getUrl, getBanner } from '../eventHelper';
import { Button, useRouter } from 'components';

export function EventListingWideItem({ event }) {
  const { navigate } = useRouter();
  // console.log(property);
  return (
    <div className='eventListingItem eventListingItem--wide'>
      <div className='itemLink'>
        {/* <Link className='item-link' to={getUrl(event)}> */}
        <div className='img'>
          <img src={getBanner(event)} alt={event.eventTitle} />
        </div>
        <div className='details'>
          <div className='title'>{event.eventTitle}</div>
          <div className='date'>
            <img className='icon' src='/assets/icons/calendar.svg' alt='date' />
            {event.dateText}
          </div>
          <div className='location'>
            <img className='icon' src='/assets/icons/location.svg' alt='location' />
            {event.locationText}
          </div>
          <div className='desc'>{event.shortDesc}</div>
          <div className='more'>
            <Button className='item-link' btnStyle='outline-primary' onClick={() => navigate(getUrl(event))}>
              LEARN MORE
            </Button>
          </div>
        </div>
        {/* </Link> */}
      </div>
    </div>
  );
}
