/** @jsx jsx */
import { jsx } from '@emotion/core';
import cmsUtils from '../../cmsUtils';
import utils from 'utils';
import { Gallery } from 'site/gallery/gallery';

export function PhotoGallery(props) {
  const item = props.item;
  //const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);

  const cssClass = utils.classNames('cms_item', 'photoGallery', item.cssClass || item.anchorName || '');
  const images = cmsUtils.payload(item, 'ImageUrl') ? cmsUtils.payload(item, 'ImageUrl').split('|') : [];
  const galleryImages = images.map((x) => ({ imageUrl: x, thumbnailUrl: x, caption: null }));

  return <Gallery cssClass={cssClass} images={galleryImages} useSlider={false} />;
}
