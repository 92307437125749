import React from 'react';
import { EventListing } from 'site/event/eventListing/eventListing';
import cmsUtils from 'site/_cmsItems/cmsUtils';

export function EventListingView({ item, cmsOption }) {
  //const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);

  const limit = cmsUtils.payload(item, 'Limit');
  const displayType = cmsUtils.payload(item, 'Display');
  const useSlider = cmsUtils.payload(item, 'UseSlider');

  if (cmsOption.isCmsEdit)
    return (
      <pre
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        style={{
          fontSize: '12px',
          margin: '5px',
          padding: '10px',
          backgroundColor: '#eee',
          border: 'border: 1px solid #ccc;',
        }}
      >
        <b>Type: {item?.itemTypeCode}</b>
      </pre>
    );

  return <EventListing limit={limit || 6} displayType={displayType || 'tile'} useSlider={useSlider} />;
}
