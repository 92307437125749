import React from 'react';

export function FieldError({ error }) {
  const errorMessage = error?.message || error;
  return errorMessage ? <div className='errorMessage errorMessage--small'>{errorMessage}</div> : null;
}

export function ServerErrors({ errors }) {
  const errorMessage = errors?.message || errors;
  // console.log(errorMessage, errors);
  return errorMessage ? <div className='errorMessage errorMessage--small'>{errorMessage}</div> : null;
}
