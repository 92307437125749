import { ErrorPopup, Loading, useGet } from 'components';
import env from 'env';
import _ from 'lodash';
import React from 'react';
// import { EventListingSliderView } from './sliderView';
import { EventListingTileView } from './tilsView';

export function EventListing({ limit, displayType, useSlider }) {
  const [filter, setFilter] = React.useState(getFilterFromStorage());
  const [filterOptions, setFilterOptions] = React.useState({});

  //#region filter
  function onFilterChanged(filterChanges) {
    setFilter((prev) => ({ ...prev, ...filterChanges }));
    setFilterToStorage({ ...filter, ...filterChanges });
  }

  function setFilterToStorage(f) {
    window.localStorage.setItem('eventListFilter', JSON.stringify(f));
  }

  function getFilterFromStorage() {
    const f = window.localStorage.getItem('eventListFilter');
    return f
      ? JSON.parse(f)
      : {
          state: '',
          eventType: '',
          categoryId: '',
          cpd: '',
        };
  }
  //#endregion
  const get = useGet();
  // console.log(limit, displayType, useSlider);

  React.useEffect(() => {
    get.send(`${env.apiBase}/api/event/getEvents`);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (get.done()) {
      const options = possiblyFilterOnly(get.response.events, get.response.categories);
      const newFilter = { ...filter };
      if (filter.state && !_.find(options.states, (x) => x.value === filter.state)) newFilter.state = '';
      if (filter.categoryId && !_.find(options.categories, (x) => x.value === filter.categoryId))
        newFilter.categoryId = '';
      if (filter.eventType && !_.find(options.eventTypes, (x) => x.value === filter.eventType))
        newFilter.eventType = '';

      console.log(newFilter, options);
      setFilter(newFilter);
      setFilterOptions(options);
    } // eslint-disable-next-line
  }, [get.status]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }
  const data = get.response;
  return (
    <>
      <EventListingTileView
        displayType={displayType}
        events={data.events}
        filter={filter}
        filterOptions={filterOptions}
        onFilterChanged={onFilterChanged}
      />
      {/* {useSlider ? (
        <EventListingSliderView displayType={displayType} events={data.events} />
      ) : (
        <EventListingTileView displayType={displayType} events={data.events} />
      )} */}
    </>
  );
}

function possiblyFilterOnly(events, categories) {
  const eventTypeDef = [
    { eventType: 'faceToFace', name: 'Face to Face' },
    { eventType: 'online', name: 'Online' },
  ];
  // const cpdDef = [
  //   { value: true, name: 'CPD' },
  //   { value: false, name: 'Non CPD' },
  // ];
  const states = _.uniq(events.map((x) => x.state).filter((x) => !!x));
  const categoryIds = _.uniq(events.map((x) => x.categoryId).filter((x) => !!x));
  const eventTypes = _.uniq(events.map((x) => x.eventType).filter((x) => !!x));
  // const hasCPD = _.indexOf(events, e => e.cpd && e.cpd > 0) >= 0;

  // console.log(events, categories, states, categoryIds, eventTypes);

  const possibleFilters = {
    states: states.map((x) => ({ name: x, value: x })),
    categories: categories
      .filter((x) => categoryIds.indexOf(x.categoryId) >= 0)
      .map((x) => ({ name: x.categoryName, value: x.categoryId })),
    eventTypes: eventTypeDef
      .filter((x) => eventTypes.indexOf(x.eventType) >= 0)
      .map((x) => ({ name: x.name, value: x.eventType })),
  };

  // console.log(possibleFilters);
  return possibleFilters;
}
