// import { formatNumber } from 'lib/utils-core/text';
// import env from 'env';
// import React from 'react';
import utils from 'utils';

export function getBanner(event) {
  const image = event?.mainImageUrl;
  if (image) {
    return utils.site.resourcePath(image);
  }
  return utils.site.resourcePath('/sites/chiroevents/media/banners/adobestock_287815077.jpg');
}

export function getUrl(event) {
  return `/event/${event.uniqueCode}`;
  // return `/event/${event.uniqueCode}-${utils.url.getSlug(event.title)}`;
}

export function getEventIdFromUrl(path) {
  if (!path) return null;
  const parts = path.split('-');
  if (parts && parts.length > 0) return parts[0];
  return null;
}
