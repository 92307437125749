import React from 'react';
import { Popup } from 'site/_popup/popup';
import { RegisterForm } from './registerForm';
import { SiteLink } from 'components';
import { useAuth } from 'auth';
import { getEventUrl } from 'site/event/eventUtils';

export function RegisterPopup({ showPopup, onClosed, event }) {
  const title = event.eventTitle;
  const auth = useAuth();
  const url = getEventUrl(event);

  return (
    <Popup showPopup={showPopup} onClosed={onClosed} maxHeight='500px'>
      <div className='mx-2'>
        <h3 className='font-bold'>
          {title}
          {/* {event.price != null && (
            <span className='ml-2'> - {(event.price || 0) === 0 ? 'FREE' : utils.money.formatMoney(event.price)}</span>
          )} */}
        </h3>

        {auth.loggedInSync && (
          <div style={{ marginTop: '2rem' }}>
            <p>Please fill out the following registration form.</p>

            <div style={{ margin: '1rem 0' }}>
              <RegisterForm event={event} onClosed={onClosed} />
            </div>
          </div>
        )}
        {!auth.loggedInSync && (
          <div style={{ marginTop: '2rem' }}>
            <p>
              <strong>Please log in first to register this event.</strong>
            </p>
            <div>
              <SiteLink to={`/member/login?returnUrl=${url}`} className='btn btn-primary btn-lg'>
                LOG IN
              </SiteLink>
              <SiteLink
                to={`/member/signup?returnUrl=${url}`}
                className='btn btn-outline-primary'
                style={{ marginLeft: '1rem' }}
              >
                SIGN UP
              </SiteLink>
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
}
