import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
import utils from 'utils';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/about', title: 'ABOUT', icon: 'navicon-chiro-events.png', iconHover: 'navicon-chiro-events.png' },
    { url: '/listings', title: 'LISTINGS', icon: 'navicon-chiro-events.png', iconHover: 'navicon-chiro-events.png' },
    { url: '/signup', title: 'SIGN-UP', icon: 'navicon-chiro-events.png', iconHover: 'navicon-chiro-events.png' },
    { url: '/information', title: 'INFORMATION', icon: 'navicon-chiro-events.png', iconHover: 'navicon-chiro-events.png' },
    { url: '/contact', title: 'CONTACT', icon: 'navicon-chiro-events.png', iconHover: 'navicon-chiro-events.png' },
  ];

  //const path = usePath();
  const { pathname, navigate } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  function linkClicked(e, url) {
    if (url === '#contact') {
      e.preventDefault();
      e.stopPropagation();
      if (utils.ui.findElement(url)) {
        utils.ui.scrollTo(url);
      } else {
        navigate('/#contact');
      }
    }
  }

  const linkElts = links.map((link, index) => (
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
        onClick={(e) => linkClicked(e, link.url)}
      >
        <div className='icon'>
          <img className='normal' src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className='selected' src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className='bottomMenu'>
        {/* <div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div> */}
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
      {/* <div className="bottomMenuPadding"></div> */}
    </>
  );
}