import React from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FieldError, ServerErrors } from 'site/_helpers';

const schema = yup.object().shape({
  email: yup.string().nullable().required('Email is required.'),
  firstName: yup.string().nullable().required('First Name is required.'),
  surname: yup.string().nullable().required('Surname is required.'),
  phone: yup.string().nullable(),
});

export function DetailsForm({ cart, saveShipping }) {
  // console.log(cart.billingAddress);
  const defaultValues = cart.billingAddress;

  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [serverErrors, setServerErrors] = React.useState([]);

  async function onSubmit(model) {
    try {
      await saveShipping({ billingInfo: model, useSameAddress: true });
    } catch (e) {
      reset(model);
      setServerErrors(e);
    }
  }

  // console.log(isValid, dirtyFields, isSubmitting, errors);
  return (
    <div className=''>
      <h3>BILLING INFORMATION</h3>
      <form method='POST' noValidate='' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='firstName'>First Name</label>
              <input id='firstName' type='text' className='form-control' {...register('firstName')} />
              <FieldError error={errors?.firstName} />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='surname'>Surname</label>
              <input id='surname' type='text' className='form-control' {...register('surname')} />
              <FieldError error={errors?.surname} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input id='email' type='email' className='form-control' {...register('email')} />
              <FieldError error={errors?.email} />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='phone'>Phone</label>
              <input id='phone' type='text' className='form-control' {...register('phone')} />
              <FieldError error={errors?.phone} />
            </div>
          </div>
        </div>

        <div className='form-group m-0 text-center'>
          <button type='submit' disabled={isSubmitting} className='btn btn-primary'>
            {isSubmitting && <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>}
            <span className=''>Next</span>
          </button>
        </div>

        <ServerErrors errors={serverErrors} />
      </form>
    </div>
  );
}
