import React from 'react';
import { EventListingItem } from './small/eventListingItem';
import { EventListingWideItem } from './wide/eventListingWideItem';

export function EventListingTileView({ events, displayType, filter, filterOptions, onFilterChanged }) {
  const changeFilter_State = (e) => {
    onFilterChanged({ state: e.target.value });
  };

  const changeFilter_Category = (e) => {
    onFilterChanged({ categoryId: e.target.value });
  };

  const changeFilter_EventType = (e) => {
    onFilterChanged({ eventType: e.target.value });
  };

  const changeFilter_Cpd = (e) => {
    onFilterChanged({ cpd: e.target.value });
  };

  console.log(filter);

  const filtered = events.filter(
    (ev) =>
      (filter.state ? ev.state === filter.state : true) &&
      (filter.categoryId ? ev.categoryId === filter.categoryId : true) &&
      (filter.eventType ? ev.eventType === filter.eventType : true) &&
      (filter.cpd ? (filter.cpd === 'cpd' ? ev.cpd && ev.cpd > 0 : !ev.cpd || ev.cpd === 0) : true)
  );
  return (
    <>
      <div className='eventListingFilterBar'>
        <div className='filters'>
          <select name='' className='form-control' value={filter.state} onChange={changeFilter_State}>
            <option value=''>State - Any</option>
            {(filterOptions.states || []).map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>

          <select name='' className='form-control' value={filter.categoryId} onChange={changeFilter_Category}>
            <option value=''>Category - Any</option>
            {(filterOptions.categories || []).map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>

          <select name='' className='form-control' value={filter.eventType} onChange={changeFilter_EventType}>
            <option value=''>Event Type - Any</option>
            {(filterOptions.eventTypes || []).map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>
          <select name='' className='form-control' value={filter.cpd} onChange={changeFilter_Cpd}>
            <option value=''>CPD - Any</option>
            <option value='cpd'>CPD Events</option>
            <option value='non-cpd'>Non CPD</option>
          </select>
        </div>
      </div>
      <div className={`eventListing ${displayType === 'wide' ? 'eventListing--wide' : ''}`}>
        {filtered.map((ev) =>
          displayType === 'wide' ? (
            <EventListingWideItem key={ev.eventId} event={ev} />
          ) : (
            <EventListingItem key={ev.eventId} event={ev} />
          )
        )}
        {filtered.length === 0 && <div>No listing</div>}
      </div>
    </>
  );
}
