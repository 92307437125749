/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import env from 'env';
import { GuideTiles } from 'site/ecm/guide/guideTiles';
import { useGet } from 'components';
import { Loading, ErrorPopup } from 'components';

export function GuideTilesLoading({ pageUrl }) {
  const get = useGet();

  React.useEffect(() => {
    get.send(`${env.apiBase}/api/guide/getGuides?cat=${encodeURIComponent(pageUrl)}`);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  const data = get.response;
  return (
    <div>
      <GuideTiles guides={data.guides} />
    </div>
  );
}
