import React from 'react';
import { SiteLink } from 'components';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';

export function MyBookmarks({ dashboard }) {
  return (
    <div>
      <SiteLink to='/member/bookmarks'>
        <BsFillBookmarkCheckFill /> <strong>Bookmarked Events</strong>
      </SiteLink>
    </div>
  );
}
