import React, { useContext, createContext } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import config from 'config';
import env from 'env';
import { getFirebaseUser } from './authHelper';
import { fetchPost } from 'lib/net-api/fetch';
// import { userState } from 'atom-store';
// import { useSetRecoilState } from 'recoil';

firebase.initializeApp(config.firebaseSettings);

const authContext = createContext();

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  //console.log('ProvideAuth')
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  // const setAtomUserState = useSetRecoilState(userState);
  const [authData, setAuthData] = React.useState({ user: null, queryStatus: 'loading', memberInfo: null });
  const user = authData.user;
  const setUser = (user) => {
    // setAtomUserState(user);
    setAuthData({ ...authData, user: user, queryStatus: 'resolved' });
  };
  const setMemberInfo = (memberInfo) => {
    if (!authData.memberInfo || JSON.stringify(authData.memberInfo) !== JSON.stringify(memberInfo))
      setAuthData({ ...authData, memberInfo: memberInfo });
  };
  const isResolved = authData.queryStatus === 'resolved';

  //#region Firebase methods
  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (response) => {
        await fetchPost(env.apiBase + '/api/profile/UpdateUserProfile', {
          uid: response.user.uid,
          email: response.user.email,
          displayName: response.user.displayName,
          emailVerified: response.user.emailVerified,
        });
        setUser(response.user);
        return response.user;
      });
  };

  const signup = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (response) => {
        await fetchPost(env.apiBase + '/api/profile/UpdateUserProfile', {
          uid: response.user.uid,
          email: response.user.email,
          displayName: response.user.displayName,
          emailVerified: response.user.emailVerified,
        });
        setUser(response.user);
        return response.user;
      });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(async () => {
        setUser(null);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };
  //#endregion

  const currentFirebaseUser = async () => await getFirebaseUser();
  const loggedIn = async () => {
    if (user) return user;
    else return await getFirebaseUser();
  };

  const loggedInSync = () => {
    return !!user;
  };

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      //console.log('onAuthStateChanged', user)
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  // Return the user object and auth methods
  return {
    authData,
    user,
    isResolved,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    // status
    loggedIn,
    loggedInSync,
    currentFirebaseUser,
    // set
    setMemberInfo,
  };
}
