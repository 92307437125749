import React from 'react';
import { SiteLink } from 'components';
import { IoCalendarOutline } from 'react-icons/io5';

export function EventManagement({ dashboard }) {
  return (
    <div>
      <div>
        <SiteLink to='/member/events'>
          <IoCalendarOutline /> <strong>Event Management</strong>
        </SiteLink>
      </div>
    </div>
  );
}
