import React from 'react';
import { Route } from 'react-router-dom';
import { SingleBannerPanel } from 'site/singleBanner/singlePanel';
import utils from 'utils';

import { MenuLoader } from './menuLoader';

const r = utils.site.resourcePath;
const bannerImage = '/sites/chiroevents/media/banners/adobestock_287815077.jpg';

const DashboardLayout = ({ children, ...rest }) => {
  return (
    <div style={{ paddingBottom: '6rem' }}>
      <SingleBannerPanel heightSet={'thin'} bgUrl={r(bannerImage)} />
      {children}
    </div>
  );
};

const DashboardNoAuthLayoutRoute = ({ component: RenderComponent, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <MenuLoader />
          <RenderComponent {...matchProps} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardNoAuthLayoutRoute;
